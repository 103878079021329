import { Common } from '@stockbit/ui';
import Image from 'global/Image';
import { useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import clsx from 'clsx';
import { BenefitWrapper, BenefitTitle, BenefitDescription } from './styled';
import { Wrapper, SectionTitle } from '../styled';
import { communityBenefits } from '../constants';

const { Flex } = Common;

function CommunityBenefit() {
  const [viewed, setViewed] = useState(false);

  return (
    <ReactVisibilitySensor
      offset={{ top: 140, bottom: 140 }}
      partialVisibility
      onChange={(v) => setViewed((s) => s || v)}
    >
      <Wrapper align="center">
        <SectionTitle className="fadeInX fadeIn">
          Keuntungan dari Kebersamaan
        </SectionTitle>
        <BenefitWrapper>
          {communityBenefits.map((benefit) => (
            <Flex
              data-name="benefitColumn"
              direction="column"
              align="center"
              key={benefit.title}
            >
              <Image
                src={benefit.image}
                alt={benefit.title}
                className={clsx('fadeInTopX', viewed && 'fadeInTop')}
                style={{ margin: '20px 0', width: '190px' }}
              />
              <Flex
                className="fadeIn fadeInX"
                direction="column"
                align="center"
              >
                <BenefitTitle>{benefit.title}</BenefitTitle>
                <BenefitDescription>{benefit.description}</BenefitDescription>
              </Flex>
            </Flex>
          ))}
        </BenefitWrapper>
      </Wrapper>
    </ReactVisibilitySensor>
  );
}

export default CommunityBenefit;

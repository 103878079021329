import styled from 'styled-components';
import { Common, device } from '@stockbit/ui';

const { Text } = Common;

const BenefitWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media ${device.tablet} {
    grid-template-columns: repeat(3, 1fr);
    padding-top: 30px;
  }

  @media ${device.laptop} {
    grid-gap: 60px;
    padding-top: 50px;
  }

  [data-name='benefitColumn']:nth-child(1) .fadeInTop {
    animation-delay: 0.1s;
  }

  [data-name='benefitColumn']:nth-child(2) .fadeInTop {
    animation-delay: 0.25s;
  }

  [data-name='benefitColumn']:nth-child(3) .fadeInTop {
    animation-delay: 0.4s;
  }
`;

const BenefitTitle = styled(Text).attrs({
  as: 'h2',
  align: 'center',
  family: 'bold',
  mb: '30px',
})`
  font-size: 20px;

  @media ${device.laptop} {
    font-size: 28.5px;
  }
`;

const BenefitDescription = styled(Text).attrs({
  family: 'light',
  align: 'center',
})`
  font-size: 17px;

  @media ${device.laptop} {
    font-size: 19px;
  }
`;

export { BenefitWrapper, BenefitTitle, BenefitDescription };
